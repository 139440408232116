<template>
  <div class="login">
    <h1>Войти</h1>

    <div>
      <form @submit.prevent="submit">
        <div>
          <label for="username">E-mail:</label>
          <input type="text" name="username" v-model="form.mail" />
        </div>
        <div>
          <label for="password">Пароль:</label>
          <input type="password" name="password" v-model="form.password" />
        </div>
        <button type="submit">Отправить</button>
      </form>
      <p v-if="showError" id="error">Неправильный логин или пароль</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        mail: "",
        password: "",
      },
      showError: false
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      // let User = new FormData();
      // User.append("mail", this.form.mail);
      // User.append("password", this.form.password);
      // console.log('User',User)
      try {
        await this.LogIn(this.form);
        this.showError = false
        await this.$router.push('/applist')
      } catch (error) {
        console.log('err',error)
        this.showError = true
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}
button[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  cursor: pointer;
  border-radius:30px;
}
button[type=submit]:hover {
  background-color: #45a049;
}
input {
  margin: 5px;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  padding:10px;
  border-radius:30px;
}
#error {
  color: red;
}
</style>