<template>
<div>
  <h1>Приложения</h1>
  <div style="display: flex; flex-direction: column">
    <v-btn elevation="2" style="align-self: flex-end; margin-bottom: 10px" @click="createApp">Добавить</v-btn>
    <v-card>
<v-data-table
:headers="headers"
:items="apps"
@click:row="modalClick"
hide-default-footer>
<template v-slot:item.creator="{ item }" >
  {{ item.creator[0].first_name + ' ' + item.creator[0].last_name}}
</template>
</v-data-table>
    </v-card>
  </div>
  <div >  <AppsModal :visible="modal" :value="value" @close="modal=false" :modalName="modalName"></AppsModal></div>
</div>
</template>

<script>
import AppsModal from "../components/AppsModal";

export default {
  name: "Home",
  components: {
    AppsModal
  }
,
  data() {
    return {
      headers: [
        {text:"Название", value:"name"},
        {text:"Создатель", value:"creator"},
        {text:"Количество приложений", value:"count"},
      ],
      apps:[
      ],
      modal:false,
      value: {
        name: '',
        creator: [],
        creators: [],
      },
      creators: [],
      modalName: '',
    }
  },
  async mounted() {
    const {data} = await this.$http.get('/apps/');
    this.apps = data;
  },
  methods: {
    async modalClick(row) {
      this.modal = true;
      const {data} = await this.$http.get('/apps/id/'+row._id)
      const creators = await this.$http.get('/apps/creators')
      this.value = data;
      this.value.creators= creators.data;
      this.modalName='Редактировать приложение'
    },
    async createApp() {
      this.modal = true;
      this.value = {
        name: '',
        creator: [],
        creators: [],
      };
      const creators = await this.$http.get('/apps/creators')
      this.value.creators= creators.data;
      this.modalName='Создать приложение'
    }
  },
}
</script>

<style scoped>

</style>