<template>
  <div class="register">
    <h1>Зарегистрироваться</h1>
    <div>
      <form @submit.prevent="submit">
        <div>
          <label for="username">Имя:</label>
          <input type="text" name="username" v-model="form.first_name" />
        </div>
        <div>
          <label for="full_name">Фамилия:</label>
          <input type="text" name="full_name" v-model="form.last_name" />
        </div>
        <div>
          <label for="password">Пароль:</label>
          <input type="password" name="password" v-model="form.password" />
        </div>
        <div>
          <label for="phone">Телефон:</label>
          <input type="text" name="phone" v-model="form.phone" />
        </div>
        <div>
          <label for="mail">E-mail:</label>
          <input type="text" name="mail" v-model="form.mail" />
        </div>
        <button type="submit">Отправить</button>
      </form>
    </div>
    <p v-if="showError" id="error">Пользователь уже существует</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Register",
  components: {},
  data() {
    return {
      form: {
        mail: "",
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        role: "manager",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(["Register"]),
    async submit() {
      try {
        await this.Register(this.form);
        this.showError = false;
        this.$router.push('/applist')
      } catch (error) {
        console.log(error)
        this.showError = true;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}
button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 30px;
}
button[type="submit"]:hover {
  background-color: #45a049;
}
input {
  margin: 5px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 30px;
}
#error {
  color: red;
}
</style>
