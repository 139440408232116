import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register";
import Login from "../views/Login";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {requiresAuth: true, title: 'Приложения'}
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {requiresAuth:true}
  },
  {
    path: "/creators",
    name: "Creators",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Creators.vue"),
    meta: {requiresAuth:true, title: 'Создатели'}
  },
  {
    path: "/tariffs",
    name: "Tariffs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Tariffs.vue"),
    meta: {requiresAuth:true, title: 'Тарифы'}
  },
  {
    path: "/modules",
    name: "Modules",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Modules.vue"),
    meta: {requiresAuth:true, title: 'Модули'}
  },
  {
    path: "/owners",
    name: "Owners",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Owners.vue"),
    meta: {requiresAuth:true, title: 'Партнеры'}
  },
  {
    path: "/managers",
    name: "Managers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Managers.vue"),
    meta: {requiresAuth:true, title: 'Менеджеры'}
  },
  {
    path: "/clients",
    name: "Clients",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Clients.vue"),
    meta: {requiresAuth:true, admin: true, title: 'Клиенты'}
  },
  {
    path: "/applist",
    name: "AppList",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/AppList.vue"),
    meta: {requiresAuth:true, title:'Лицензии'}
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { guest: true , title: 'Регистрация' },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guest: true, title: 'Вход' },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title= to.meta.title;
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  }  else next()
})

export default router;
