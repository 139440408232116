<template>

  <div id="app">
    <div style="margin-bottom: 25px" id="nav">
      <div>
        <v-app-bar>
          <div v-if="userRole==='admin'">
          <router-link to="/" > <v-btn outlined style="margin-right: 10px">Приложения</v-btn></router-link>
          <router-link to="/creators"> <v-btn outlined style="margin-right: 10px">Создатели</v-btn></router-link>
          <router-link to="/tariffs"> <v-btn outlined style="margin-right: 10px">Тарифы</v-btn></router-link>
          <router-link to="/modules"> <v-btn outlined style="margin-right: 10px">Модули</v-btn></router-link>
          <router-link to="/owners"> <v-btn outlined style="margin-right: 10px">Партнеры</v-btn></router-link>
          <router-link to="/managers"> <v-btn outlined style="margin-right: 10px">Менеджеры</v-btn></router-link>
          <router-link to="/clients"> <v-btn outlined style="margin-right: 10px">Клиенты</v-btn></router-link>

          </div>
          <router-link to="/applist" v-if="isLoggedIn"> <v-btn outlined style="margin-right: 10px">Лицензии</v-btn></router-link>
          <span v-if="isLoggedIn">
      <a @click="logout"><v-btn outlined style="margin-right: 5px">Выйти</v-btn></a>
    </span>

          <span v-else>
      <router-link to="/register"><v-btn outlined style="margin-right: 10px">Регистрация</v-btn></router-link>
      <router-link to="/login"><v-btn outlined style="margin-right: 5px">Вход</v-btn></router-link>
    </span>


        </v-app-bar>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
    userRole: function () {return this.$store.getters.StateRole},
  },
  methods: {
    async logout (){
      await this.$store.dispatch('LogOut')
      this.$router.push('/login')
    }
  },
}
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
</style>
