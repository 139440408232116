import axios from "axios";

const state = {
    user: null,
    role: null,
};

const getters = {
    isAuthenticated: (state) => !!state.user,
    StateRole: (state) => state.role,
    StateUser: (state) => state.user,
};

const actions = {
    async Register({dispatch}, form) {
        await axios.post('/managers/register', form)
        let UserForm = new FormData()
        UserForm.append('mail', form.mail)
        UserForm.append('password', form.password)
        await dispatch('LogIn', UserForm)
    },

    async LogIn({commit}, user) {
        let {data} = await axios.post("/managers/login", user);
        console.log(data)
        if(data.access_token) {
            await commit("setUser", data.mail);
            await commit("setRole", data.role);
        }

    },
    async LogOut({ commit }) {
        let user = null;
        commit("logout", user);
    },
};

const mutations = {
    setUser(state, username) {
        state.user = username;
        console.log('user',state.user)
    },

    setRole(state, role) {
        state.role = role;
        console.log('role',state.role)
    },
    logout(state, user) {
        state.user = user;
        state.role= null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};