import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import axios from "axios";

Vue.use(Vuex);

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default new Vuex.Store({

  state: {},
  mutations: {},
  actions: {},
  modules: {auth,},
  plugins: [createPersistedState()],
});
